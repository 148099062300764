<template>
    <header class="header">
        <div class="op-balb">
            <img src="/img/Untitled-1-07.png" alt="">
        </div>
        <div class="row slider align-items-center">
            <div class="col-7">
                <div class="balb"><img src="/img/Untitled-1-05.png" alt="orange-balb"></div>
                <img :src="'/img/'+logo.name" :ref="'mainlogo'+logo.id" :id="'mainlogo'+logo.id"  v-for="logo in logos" :key="logo.id" class="main-logo" :class="{hide:logo.hidden}" alt="AzarpajoheshGroup">
                <div class="lights animate-zoom">
                    <img src="/img/upLight.png" class="up-light" :class="{'up-animation' : upAnimation}" v-on:animationend="removeClass()" alt="lights">
                    <img src="/img/downLight.png" class="down-light" :class="{'down-animation' : downAnimation}" v-on:animationend="removeClass()" alt="lights">
                </div>
            </div>
            <div class="col-5">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-10 col-10 align-self-center">
                        <router-link :to="{ name: 'Category', params: { language: this.$i18n.locale,category:'plast' }}" class="btn-plast mb-2">
                            <img src="/img/9-02.png" alt="">
                        </router-link>
                        <router-link :to="{ name: 'Category', params: { language: this.$i18n.locale,category:'tajhiz' }}" class="btn-tajhiz mt-2">
                            <img src="/img/9-01.png" alt="">
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name:'Header',
        data(){
            return{
                logos:[
                        {
                            id:1,
                            name:'Untitled-1-01.png',
                            hidden : false,
                        },
                        {
                            id:2,
                            name:'Untitled-1-02.png',
                            hidden : true,
                        },
                        {
                            id:3,
                            name:'Untitled-1-03.png',
                            hidden:true,
                        }
                    ],
                upAnimation:false,
                downAnimation:false,
                activeLogo:1,
            }
        },
        components:{
            
        },
        mounted(){
            setTimeout(()=>{
                this.upAnimation = true;
                this.downAnimation = true;
            },10000);

            
        },
        methods:{
            removeClass(){
                this.upAnimation=false,
                this.downAnimation = false,
                this.addClass();
            },
            addClass(){
                setTimeout(()=>{
                    this.upAnimation = true;
                    this.downAnimation = true;
                },10000);
            },
            addClassLogo(){
               var ar = this.logos;
               var activeid = Number(this.activeLogo) + 1;
               var active = ar.find(x=>x.id == this.activeLogo);
               if (active) {
                   active.hidden = true;
                   console.log(ar)
               }
               var act = ar.find(x=>x.id == activeid);
               if(act){
                   act.hidden = false;
                   this.activeLogo = activeid;
               }else{
                   this.activeLogo = 0;
                   this.addClassLogo();
               }
               return this.logos = ar;
            },
            moveLogo(){
                var activeid = '#mainlogo'+this.activeLogo;
                this.$anime({
                    targets: activeid,
                    translateX: {
                        value: -210,
                        duration: 1200
                    },
                    scale: {
                        value: 1.2,
                        duration: 1600,
                        delay: 2000,
                        easing: 'easeInOutQuart'
                    },
                    direction: 'alternate',
                    delay: 500 // All properties except 'scale' inherit 250ms delay
                    });
            },
            btn(){
                this.$anime({
                    targets: '.btn-plast , .btn-tajhiz',
                        scale: {
                        value: 1.2,
                        duration: 1200,
                        easing: 'easeInOutQuart',
                        
                    },
                    direction:'alternate',
                    delay:500,
                });
            }
        }
    }
</script>

<style lang="scss">
#nav{
    display: none;
}
.slider{
    width: 100%;
    margin-top: 7%;
    position: relative;
    padding-right: 20px;
    margin-right: 0;
    background: rgb(255,255,255);
    background: linear-gradient(90deg,rgba(255,255,255,0.2) 0% ,rgba(255,255,255,0)100%);
}
.balb{
    position: relative;
    width: 10%;
    margin-top: 25px;
    margin-right: -5%;
    
}
@media (max-width: 600px){
    .btn-tajhiz{
        padding-bottom: -10px;
    }
    .btn-plast{
        padding-bottom: -10px;
    }
    .balb{
        margin-right: -20%;
    }
}
.balb img{
    margin-right: 0;
    width: 100%;
    height: 100%;
    
}
.op-balb{
    position: absolute;
    top: -2%;
    left: 5%;
    width: 30%;
    overflow: hidden;
}
.op-balb img{
    width: 100%;
}
.main-logo{
    width: 43%;
    position: absolute;
    left: 40%;
    top: 18%;
}
.lights{
    width: 37%;
    position: absolute;
    top: -17%;
    left: 15%;

}
.lights img{
    width: 100%;
    height: 100%;
}
.down-light{
    margin-top: 40%;
    margin-right: -5px;
    
}
.down-animation{
    transform-origin: top right;
    transform-style: preserve-3D;
    animation: spin2 1s linear;
}
.up-animation{
    transform-origin: bottom right;
    transform-style: preserve-3D;
    animation: spin2R 1s linear;
}
.animate-input{transition:width 0.4s ease-in-out}.w3-animate-input:focus{width:100%!important}
.animate-zoom {animation:animatezoom 0.6s}@keyframes animatezoom{from{transform:scale(0)} to{transform:scale(1)}}
.btn-tajhiz{
    display: inline-block;
    background: rgb(211,76,22);
    background: linear-gradient(90deg, rgba(211,76,22,1) 0%, rgba(250,189,40,1) 100%);
    border-radius: 25px;
    padding: 0 10px 0 10px;
    color: #fff;
    transition: 3s;
    vertical-align: middle;
}
.btn-tajhiz:hover{
    text-decoration: none;
    animation: effect-tajhiz 0.7s ease-in ;
    color: #fff;
}
@keyframes spin {
        0% {
          transform: translate(0,0);
          
        }
        5%{
            transform: translate(0,-6em);
        }
        10% {
          transform: translate(0,0);
        }
        100%{
            transform: translate(0,0);
        }
      }
@keyframes spinR {
        0% {
          transform: translate(0,0);
          
        }
        5%{
            transform: translate(0,6em);
        }
        10% {
          transform: translate(0,0);
        }
        100%{
            transform: translate(0,0);
        }
      }
@keyframes spin2 {
    from,to{transform: rotate(0deg);}
    50%{transform: rotate(90deg);}
}
@keyframes spin2R {
    from,to{transform: rotate(0deg);}
    50%{transform: rotate(-90deg);}
}
@keyframes moveIt {
  from {transform: rotate(0deg); animation-timing-function: cubic-bezier(0.1, 0.3, 0.3, 1);}
  10% {transform: rotate(-40deg); animation-timing-function: cubic-bezier(0.7, 0.0, 0.9, 0.7);}
  80% {transform: rotate(0deg); animation-timing-function: cubic-bezier(0.1, 0.3, 0.3, 1);}
  90% {transform: rotate(20deg); animation-timing-function: cubic-bezier(0.7, 0.0, 0.9, 0.7);}
  to {transform: rotate(0deg);}
}
@keyframes moveItR{
    from {transform: rotate(0deg); animation-timing-function: cubic-bezier(0.1, 0.3, 0.3, 1);}
  10% {transform: rotate(40deg); animation-timing-function: cubic-bezier(0.7, 0.0, 0.9, 0.7);}
  80% {transform: rotate(0deg); animation-timing-function: cubic-bezier(0.1, 0.3, 0.3, 1);}
  90% {transform: rotate(-20deg); animation-timing-function: cubic-bezier(0.7, 0.0, 0.9, 0.7);}
  to {transform: rotate(0deg);}
}
@keyframes effect-tajhiz{
    0%{background: linear-gradient(90deg, rgba(211,76,22, 0.2) 0%, rgba(250,189,40, 0) 100%);}
    10%{background: linear-gradient(90deg, rgba(211,76,22, 0.5) 0%, rgba(250,189,40, 0) 100%);}
    20%{background: linear-gradient(90deg, rgba(211, 75, 22, 0.8) 0%, rgba(250, 190, 40, 0.2) 100%);}
    30%{background: linear-gradient(90deg, rgba(211, 75, 22, 0.9) 0%, rgba(250, 190, 40, 0.3) 100%);}
    40%{background: linear-gradient(90deg, rgba(211, 75, 22, 1) 0%, rgba(250, 190, 40, 0.4) 100%);}
    50%{background: linear-gradient(90deg, rgba(211, 75, 22, 1) 0%, rgba(250, 190, 40, 0.5) 100%);}
    60%{background: linear-gradient(90deg, rgb(211, 75, 22,1) 0%, rgba(250, 190, 40, 0.6) 100%);}
    70%{background: linear-gradient(90deg, rgb(211, 75, 22,1) 0%, rgba(250, 190, 40, 0.7) 100%);}
    80%{background: linear-gradient(90deg, rgba(211,76,22,1) 0%, rgb(250, 190, 40,0.8) 100%);}
    90%{background: linear-gradient(90deg, rgba(211,76,22,1) 0%, rgb(250, 190, 40,0.9) 100%);}
    100%{background: linear-gradient(90deg, rgba(211,76,22,1) 0%, rgb(250, 190, 40,1) 100%);}
}
@keyframes effect-plast{
    0%{background: linear-gradient(90deg, rgba(12,125,166, 0.2) 0%, rgba(0,255,255, 0) 100%);}
    10%{background: linear-gradient(90deg, rgba(12,125,166, 0.5) 0%, rgba(0,255,255, 0) 100%);}
    20%{background: linear-gradient(90deg, rgba(12,125,166, 0.8) 0%, rgba(0,255,255, 0.2) 100%);}
    30%{background: linear-gradient(90deg, rgba(12,125,166, 0.9) 0%, rgba(0,255,255, 0.3) 100%);}
    40%{background: linear-gradient(90deg, rgba(12,125,166, 1) 0%, rgba(0,255,255, 0.4) 100%);}
    50%{background: linear-gradient(90deg, rgba(12,125,166, 1) 0%, rgba(0,255,255, 0.5) 100%);}
    60%{background: linear-gradient(90deg, rgb(12,125,166,1) 0%, rgba(0,255,255, 0.6) 100%);}
    70%{background: linear-gradient(90deg, rgb(12,125,166,1) 0%, rgba(0,255,255, 0.7) 100%);}
    80%{background: linear-gradient(90deg, rgba(12,125,166,1) 0%, rgb(0,255,255,0.8) 100%);}
    90%{background: linear-gradient(90deg, rgba(12,125,166,1) 0%, rgb(0,255,255,0.9) 100%);}
    100%{background: linear-gradient(90deg, rgba(12,125,166,1) 0%, rgb(0,255,255,1) 100%);}
}
.btn-tajhiz img{
    width: 100%;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
}
.btn-plast{
    display: block;
    padding: 0 10px 0 10px;
    background: linear-gradient(90deg, rgba(12,125,166,1) 0%, rgba(0,255,255,1) 100%);
    border-radius: 25px;
    color: #fff;
    transition: 3s;
    width: 100%;
    height: 50%;
}
.btn-plast:hover{
    text-decoration: none;
    animation: effect-plast 0.7s ease-in ;
    color: #fff;
}
.btn-plast img{
    width: 100%;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
}
.hide{
    display: none;
}
</style>