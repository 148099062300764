<template>
  <div class="home">
    <Header />
    <about border="border-main">
      <p class="text" slot="text">لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و اندازه فونت و ظاهر متن باشد.لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و اندازه فونت و ظاهر متن باشد</p>
    </about>
    <div class="pt-5 mt-5"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import About from '@/components/About';
//import Gmap from '@/components/Gmap';
export default {
  name: 'Home',
  components: {
    Header,
    About,
  }
}
</script>
<style lang="scss" scoped>
.text{
    padding: 3em;
    text-align: justify;
    font-size: 18px;
    cursor: default;

}
@media (max-width: 600px){
    .text{
        font-size: 9px;
    }
}
</style>
